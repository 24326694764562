
import { Component, Vue } from "vue-property-decorator";

class APIParam {
  constructor(
    public name: string,
    public description: string,
    public type: string,
    public scrollTo?: string,
    public edited?: boolean
  ) {}
}

class APIDescription {
  constructor(
    public id: string,
    public title: string,
    public caption: string,
    public params?: Array<APIParam>,
    public edited?: boolean
  ) {}
}

const steamBotOfferApiParams = [
  new APIParam(`id`, `ID обмена в нашей системе.`, `String`),
  new APIParam(
    `type`,
    `Тип обмена ("DEPOSIT" | "WITHDRAW").`,
    `SteamBotOfferType`,
    `#steam-bot-offer-type`
  ),
  new APIParam(
    `state`,
    `Статус обмена (-1 | 0 | 1 ... | 11 ).`,
    `SteamBotOfferState`,
    `#steam-bot-offer-state`
  ),
  new APIParam(
    `price`,
    `Совокупная стоимость предметов данного обмена.`,
    `Number`
  ),
  new APIParam(`offerId`, `ID обмена в Steam.`, `String`),
  new APIParam(
    `createdAt`,
    `Дата создания обмена (пример: "2021-02-15T11:56:33.572Z").`,
    `String`
  ),
  new APIParam(
    `steamBotId`,
    `SteamId64 бота, который принял или создал обмен.`,
    `String`
  ),
  new APIParam(
    `steamItemsIds`,
    `Названия (market_hash_name) предметов полученных или переданных<br> в результате обмена.`,
    `String[]`
  ),
  new APIParam(
    `partnerSteamId`,
    `SteamId64 пользователя создавшего или получающего обмен.`,
    `String`
  ),
  new APIParam(
    `steamBotItemsIds`,
    `ID предметов в нашей системе, которые будут переданы пользователю.`,
    `String[]`
  ),
  new APIParam(
    `paymentId`,
    `Уникальный ID платежа в Вашей системе, переданный Вами при создании обмена, <br>или offer.id, если вы не передали параметр при создании обмена.`,
    `String`,
    undefined
  ),
  new APIParam(
    `userId`,
    `ID пользователя в Вашей системе, переданный Вами при создании обмена, <br>или offer.partnerSteamId, если вы не передали параметр при создании обмена.`,
    `String`,
    undefined
  ),
  new APIParam(`appId`, `ID игры в Steam.`, `AppId`, "#app-id")
];

@Component({
  meta: {
    title: "Документация API"
  }
})
export default class API extends Vue {
  descriptions = [
    new APIDescription(
      `init`,
      `Введение`,
      `API SKINSPLUS позволяет автоматизировать работу с инвентарем Steam. Для более быстрой интеграции Вы можете воспользоваться <a class="text-primary text-weight-bold" href="https://www.npmjs.com/package/skins-plus" target="_blank">готовой библиотекой для node.js</a>.`
    ),
    new APIDescription(
      `auth`,
      `Авторизация запросов`,
      `В результате регистрации проекта Вы получите API ключ, который необходимо включить в заголовок любого запроса к API.`,
      [
        new APIParam(
          `Authorization`,
          `API ключ, полученный в результате регистрации вашего проекта.`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `webhook`,
      `Уведомления (вебхуки)`,
      `Наш сервис будет посылать POST запросы с информацией об обмене на указанный вами url-адрес.`,
      [
        new APIParam(
          `offer`,
          `Данные обмена Steam.`,
          `SteamBotOffer`,
          `#steam-bot-offer`
        ),
        new APIParam(
          `sign`,
          `Подпись для проверки того, что уведомление прислали именно мы. Формула:<br> sha224([ID обмена в нашей системе] + ':' + [Ваш API ключ]),
          пример:<br> sha224('123:a8f9babfe5db37e4eb') = '154d732bf0ad81e2e7888150944666cd2f433a06ffa52efabfbdc688'.`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `methods`,
      `Методы взаимодействия`,
      `Вы можете взаимодействовать с нашим сайтом, отправляя на него запросы для депозита и вывода предметов.`
    ),
    new APIDescription(
      `deposit`,
      `Создание депозита (вариант 1)`,
      `Все что вам нужно сделать для создания депозита - это перенаправить пользователя по ссылке вида <span class="text-primary text-weight-bold">https://skins.plus/partner/[shopId]/[partnerSteamId]</span>.
После успешной оплаты Вы получите уведомление об успешном обмене ("offer.state"=3, "offer.type"="DEPOSIT"). Будьте внимательны, проверяйте "offer.id" обмена, чтобы избежать повторного зачисления платежа.`,
      [
        new APIParam(`shopId`, `ID Вашего проекта.`, `String`),
        new APIParam(
          `partnerSteamId`,
          `SteamId64 пользователя, который будет совершать депозит.`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `depositV2`,
      `Создание депозита (вариант 2)`,
      `Все что вам нужно сделать для создания депозита - это перенаправить пользователя по ссылке вида <span class="text-primary text-weight-bold">https://skins.plus/partner/[shopId]?payment_id=[paymentId]&user_id=[userId]&min_amount=[minAmount]&sign=[sign]&success_url=[successUrl]&fail_url=[failUrl]&language=[language]&amp;currency=[currency]</span>.
	  Если Вы хотите передать successUrl и(или) failUrl не забывайте, что его нужно кодировать, подробнее об этом <a class="text-primary text-weight-bold" href="https://en.wikipedia.org/wiki/Percent-encoding" target="_blank">здесь</a>. После успешной оплаты Вы получите уведомление об успешном обмене ("offer.state"=3, "offer.type"="DEPOSIT"). Будьте внимательны, проверяйте "offer.id" обмена, чтобы избежать повторного зачисления платежа.`,
      [
        new APIParam(`shopId`, `ID Вашего проекта.`, `String`),
        new APIParam(
          `paymentId`,
          `Уникальный ID платежа в Вашей системе.`,
          `String`,
          undefined
        ),
        new APIParam(
          `userId`,
          `ID пользователя в Вашей системе.`,
          `String`,
          undefined
        ),
        new APIParam(
          `minAmount`,
          `Минимальная стоимость предметов в рублях (в совокупности) для создания обмена. Не менее 0.01.`,
          `Number`,
          undefined
        ),
        new APIParam(
          `sign`,
          `Подпись для проверки того, что платеж создан Вами и пользователь его никак не редактировал. Формула:<br> sha224([paymentId] + ':' + [userId] + ':' + [minAmount] + ':' + [Ваш API ключ]),
          пример:<br> sha224('b894b57e-3eac-4f3a-9676-a9ed066150a1:76561198831112276:5.5:a8f9babfe5db37e4eb') =<br> 'a419e12589faf5bd110f7dbeb3b563d2f536e4fe8fae85346b7e5b9d'.`,
          `String`,
          undefined
        ),
        new APIParam(
          `successUrl (необязательно)`,
          `Ссылка по которой будет перенаправлен пользователь после успешного обмена.<br> Пример: <span class="text-primary text-weight-bold">https%3A%2F%2Fsuccess-url.com</span>.`,
          `String`,
          undefined
        ),
        new APIParam(
          `failUrl (необязательно)`,
          `Ссылка по которой будет перенаправлен пользователь после неудачного обмена.<br> Пример: <span class="text-primary text-weight-bold">https%3A%2F%2Ffail-url.com</span>.`,
          `String`,
          undefined
        ),
        new APIParam(
          `language (необязательно)`,
          `Язык, на который будет переведена странице. (ru или en)`,
          `String`,
          undefined
        ),
        new APIParam(
          `currency (необязательно)`,
          `Валюта, в которую будут конвертированы цены предметов. (rub, uah, usd)`,
          `String`,
          undefined
        )
      ]
    ),
    new APIDescription(
      `items`,
      `Получение списка предметов`,
      `Вы можете получить список предметов, доступных для вывода, отправив GET запрос по ссылке <span class="text-primary text-weight-bold">https://api-sell.skins.plus/api/partner/items/v1</span>.
Результатом запроса будет массив с хэш-таблицами следующего вида:`,
      [
        new APIParam(`id`, `ID предмета в нашей системе.`, `String`),
        new APIParam(`assetId`, `ID предмета в Steam.`, `String`),
        new APIParam(
          `steamBotId`,
          `SteamId64 бота, который хранит предмет.`,
          `String`
        ),
        new APIParam(`name`, `Название (market_hash_name) предмета.`, `String`),
        new APIParam(`image`, `Картинка предмета.`, `String`),
        new APIParam(`price`, `Стоимость предмета в рублях.`, `Number`),
        new APIParam(`rarity`, `Редкость предмета.`, `String`),
        new APIParam(`exterior`, `Поношенность предмета.`, `String | null`),
        new APIParam(`type`, `Тип предмета.`, `String | null`),
        new APIParam(
          `weaponType`,
          `Тип оружия предмета, если тип предмета = 'Weapon'. ('Pistol', 'Shotgun'...)`,
          `String | null`
        ),
        new APIParam(
          `gunType`,
          `Модель оружия, если тип предмета = 'Weapon'. ('Glock', 'Usp'...)`,
          `String | null`
        ),
        new APIParam(
          `usedBy`,
          `Персонаж, который использует данный предмет. (Только для игры Dota 2)`,
          `String | null`
        ),
        new APIParam(
          `inspectLink`,
          `Ссылка для просмотра предмета в cs:go.`,
          `String | null`
        ),
        new APIParam(`float`, `Float предмета в cs:go.`, `Number | null`),
        new APIParam(
          `stickers`,
          `Сктикеры, наклеенные на предмет.`,
          `SteamBotItemSticker[]`,
          `#steam-bot-item-sticker`
        ),
        new APIParam(`appId`, `ID игры в Steam.`, `AppId`, "#app-id")
      ]
    ),
    new APIDescription(
      `prices`,
      `Получение списка стоимости предметов`,
      `Вы можете получить список стоимости предметов, доступных для депозита, для этого необходимо отправить POST запрос по ссылке <span class="text-primary text-weight-bold">https://api.skins.plus/api/partner/prices/v1</span>,
передав в теле запроса следующие параметры:`,
      [new APIParam(`appId`, `ID игры в Steam.`, `AppId`, "#app-id")],
      false
    ),
    new APIDescription(
      `prices-response`,
      ``,
      `Результатом запроса будет массив с хэш-таблицами следующего вида: `,
      [
        new APIParam(`name`, `Название (market_hash_name) предмета.`, `String`),
        new APIParam(`price`, `Стоимость предмета в рублях.`, `Number`)
      ]
    ),
    new APIDescription(
      `locales`,
      `Получение названий предметов на другом языке`,
      `Вы можете получить список предметов на другом доступном языке, для этого необходимо отправить POST запрос по ссылке <span class="text-primary text-weight-bold">https://api.skins.plus/api/partner/locales/v1</span>,
передав в теле запроса следующие парfаметры:`,
      [
        new APIParam(`appId`, `ID игры в Steam.`, `AppId`, "#app-id"),
        new APIParam(
          `language`,
          `Язык перевода предметов (ru или uk)`,
          `String`
        )
      ],
      true
    ),
    new APIDescription(
      `locales-response`,
      ``,
      `Результатом запроса будет хэш-таблица следующего вида: `,
      [
        new APIParam(`Ключ`, `Название (market_hash_name) предмета.`, `String`),
        new APIParam(
          `Значение`,
          `Переведенное название предмета на выбранный язык.`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `withdraw`,
      `Создание вывода`,
      `Для создания вывода необходимо отправить POST запрос по ссылке <span class="text-primary text-weight-bold">https://api-sell.skins.plus/api/partner/withdraw/v1</span>,
 передав в теле запроса параметры, приведенные ниже.
  Убедитесь, что установили тайм-аут соединения ~90 секунд, если все же Вы получили ошибку о тайм-ауте - не возвращайте деньги пользователю.
  Вы будете получать уведомления о созданном обмене каждый раз, когда обмен меняет "state", проверяйте "offer.state" для возврата денег клиенту.`,
      [
        new APIParam(
          `steamBotItemsIds`,
          `Массив идентификаторов предметов, полученных при запросе списка предметов.`,
          `String[]`
        ),
        new APIParam(
          `tradeUrl`,
          `Ссылка на обмен пользователя, который получит предметы.`,
          `String`
        ),
        new APIParam(
          `paymentId (необязательно)`,
          `Уникальный ID платежа в Вашей системе.`,
          `String`,
          undefined
        ),
        new APIParam(
          `userId (необязательно)`,
          `ID пользователя в Вашей системе.`,
          `String`,
          undefined
        )
      ]
    ),
    new APIDescription(
      `withdraw-response`,
      ``,
      `В ответе Вы получите информацию об обмене или ошибку с полным ее описанием. Информация об обмене имеет следующий вид: `,
      steamBotOfferApiParams
    ),
    new APIDescription(
      `withdraw-error`,
      ``,
      `Также в ответе Вы можете получить ошибку, только если 'response.statusCode' !== 200. Ответ сервера в случае возникновения ошибки будет иметь вид: `,
      [
        new APIParam(
          `statusCode`,
          `400 - в случае неправильного ввода данных в теле запроса. 500 - в случае неудачного ответа Steam при создании обмена.`,
          `Number`
        ),
        new APIParam(
          `message`,
          `Подробное описание ошибки. В случае, когда statusCode === 400, message является массивом строк, в иных - строкой.`,
          `String | String[]`
        ),
        new APIParam(
          `error`,
          `Наименование ошибки "Bad Request" или "Internal Error".`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `get-offer`,
      `Получение информации об обмене`,
      `Для получения информации об обмене необходимо отправить POST запрос по ссылке <span class="text-primary text-weight-bold">https://api-sell.skins.plus/api/partner/get-offer/v1</span>,
 передав в теле запроса параметры, хотя бы один из них, приведенные ниже. Учтите, что можете запрашивать только обмены с "type" равным "WITHDRAW", если Вам необходимо получить
информацию об обмене с "type" равным "DEPOSIT" поменяйте ссылку вашего запроса на <span class="text-primary text-weight-bold">https://api.skins.plus/api/partner/get-offer/v1</span>.`,
      [
        new APIParam(
          `id (необязательно)`,
          `ID обмена в нашей системе.`,
          `String`
        ),
        new APIParam(
          `paymentId (необязательно)`,
          `Уникальный ID платежа в Вашей системе, переданный Вами при создании обмена.`,
          `String`,
          undefined
        )
      ]
    ),
    new APIDescription(
      `get-offer-response`,
      ``,
      `В ответе Вы получите информацию об обмене или ошибку с полным ее описанием. Информация об обмене имеет следующий вид: `,
      steamBotOfferApiParams
    ),
    new APIDescription(
      `get-offer--error`,
      ``,
      `Также в ответе Вы можете получить ошибку, только если 'response.statusCode' !== 200. Ответ сервера в случае возникновения ошибки будет иметь вид: `,
      [
        new APIParam(
          `statusCode`,
          `400 - в случае неправильного ввода данных в теле запроса. 500 - в случае неудачного ответа нашего сервера.`,
          `Number`
        ),
        new APIParam(
          `message`,
          `Подробное описание ошибки. В случае, когда statusCode === 400, message является массивом строк, в иных - строкой.`,
          `String | String[]`
        ),
        new APIParam(
          `error`,
          `Наименование ошибки "Bad Request" или "Internal Error".`,
          `String`
        )
      ]
    ),
    new APIDescription(
      `types`,
      `Нестандартные типы данных`,
      `Существует 5 стандартных типов данных: String (строка), Number (число), Boolean (логический тип), Array (массив), null (void).
      Все остальные (нестандартные) типы данных являются: ограниченными версиями стандартных типов данных (например, переменная имеет тип String,
       но может принимать только 2 значения '1' или '2'), перечисляемым типом данных (Enum) или хэш-таблицами, имеющими вид { [ключ (тип String)]: [значение (любой из предоставленных типов данных)]},
      пример: {key: 'value', key1: 'value1'}. Все нестандартные типы данных выделены специальным цветом, Вы можете нажать по ним для дополнительной информации. Ниже описаны стандартные типы данных:`,
      [
        new APIParam(
          `String`,
          `Любая строка, содержащая текстовые данные.`,
          `String`
        ),
        new APIParam(
          `Number`,
          `Любое число как целое, так и дробное.`,
          `Number`
        ),
        new APIParam(
          `Boolean`,
          `Логический тип данных (true | false).`,
          `Boolean`
        ),
        new APIParam(
          `Array`,
          `Массив, содержащий в себе стандартные, либо нестандартные элементы (пример: [1,'2',true]).`,
          `Array`
        ),
        new APIParam(
          `null`,
          `Значение null представляет отсутствие какого-либо объектного значения.`,
          `null`
        )
      ]
    ),
    new APIDescription(
      `steam-bot-offer`,
      `Тип SteamBotOffer`,
      `Хэш-таблица, описывающая обмен.`,
      steamBotOfferApiParams
    ),
    new APIDescription(
      `steam-bot-offer-type`,
      `Тип SteamBotOfferType`,
      `String, принимающий 2 значения 'DEPOSIT' или 'WITHDRAW'.`
    ),
    new APIDescription(
      `steam-bot-offer-state`,
      `Тип SteamBotOfferState`,
      `Number, принимающий 12 значений, обозначающих состояние обмена:<br><br>
  UNKNOWN = -1 <br>
  NOT_CREATED = 0<br>
  INVALID = 1<br>
  ACTIVE = 2<br>
  ACCEPTED = 3<br>
  COUNTERED = 4<br>
  EXPIRED = 5<br>
  CANCELLED = 6<br>
  DECLINED = 7<br>
  INVALID_ITEMS = 8<br>
  CREATED_NEEDS_CONFIRMATION = 9<br>
  CANCELLED_BY_SECOND_FACTOR = 10<br>
  IN_ESCROW = 11
`
    ),
    new APIDescription(
      `steam-bot-item-sticker`,
      `Тип SteamBotItemSticker`,
      `Хэш-таблица, описывающая стикер, наклееный на предмет бота.`,
      [
        new APIParam(`name`, `Название (market_hash_name) стикера.`, `String`),
        new APIParam(`image`, `Ссылка на изображение стикера.`, `String`)
      ]
    ),
    new APIDescription(
      `app-id`,
      `Тип AppId`,
      `Number, принимающий 3 значения, обозначающих принадлежность к игре Steam:<br><br>
    CSGO = 730 <br>
    DOTA2 = 570 <br>
    RUST = 252490 <br>
    TF2 = 440
`,
      undefined
    )
  ];
  columns = [
    {
      name: "name",
      required: true,
      label: "Параметр",
      field: "name",
      align: "left"
    },
    {
      name: "description",
      required: true,
      label: "Описание",
      field: "description",
      align: "left"
    },
    {
      name: "type",
      required: true,
      label: "Тип",
      field: "type",
      align: "right"
    }
  ];

  created() {}
}
