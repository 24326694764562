import { render, staticRenderFns } from "./API.vue?vue&type=template&id=3b9ffb37&"
import script from "./API.vue?vue&type=script&lang=ts&"
export * from "./API.vue?vue&type=script&lang=ts&"
import style0 from "./API.vue?vue&type=style&index=0&id=3b9ffb37&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';import Ripple from 'quasar/src/directives/Ripple.js';
import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QList,QItem,QItemSection,QItemLabel,QBadge,QSeparator,QTable,QTd});qInstall(component, 'directives', {Ripple,Scroll});
